import React, { useEffect, useState } from "react";
import { TextField, Button, Box, Container, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import ReactGA from "react-ga";
import { subscribeToMailchimp } from "./mailchimp_signup";
import GoogleIcon from "@mui/icons-material/Google";
import { trackSegmentEvent } from "./segmentAnalytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const db = getFirestore();

const imageUrl = "https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/NextGen__1__w99-frOok.png?updatedAt=1695206379533";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#000000",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#000000",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
        input: {
          color: "#000000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
      },
    },
  },
});

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview("/signup");
    trackSegmentEvent("Signup Page Viewed");
  }, []);

  const handleSignup = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    trackSegmentEvent("Signup Page Signup Attempted", {
      method: "Email",
      email,
      firstName,
      lastName,
    });

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        total_video_seconds: 0,
      });

      try {
        await subscribeToMailchimp(email, firstName, lastName);
        console.log("User successfully subscribed to Mailchimp");
      } catch (error) {
        console.error("Failed to subscribe user to Mailchimp:", error);
      }

      trackSegmentEvent("Signup Successful", {
        method: "Email",
        email,
        firstName,
        lastName,
      });

      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Signup failed:", error);

      trackSegmentEvent("Signup Failed", {
        method: "Email",
        email,
        error_message: error.message,
        error_code: error.code,
      });
    }
  };

  const handleBack = () => {
    navigate("/");
    trackSegmentEvent("Signup Back Button Clicked");
  };

  const handleGoogleSignup = async () => {
    trackSegmentEvent("Google Signup Page Attempted");
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email,
        firstName: user.displayName,
        lastName: "",
        total_video_seconds: 0,
      });

      try {
        await subscribeToMailchimp(user.email, user.displayName, "");
        console.log("User successfully subscribed to Mailchimp");
      } catch (error) {
        console.error("Failed to subscribe user to Mailchimp:", error);
      }

      trackSegmentEvent("Google Signup Page Successful", {
        email: user.email,
        firstName: user.displayName,
      });

      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Google Signup failed:", error);

      trackSegmentEvent("Google Signup Failed", {
        error_code: error.code,
        error_message: error.message,
      });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
          <img
            src={imageUrl}
            alt="NextGen"
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />{" "}
          <Typography variant="h4" component="h1" gutterBottom>
            Signup
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSignup}
          sx={{
            mt: 1,
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={handleGoogleSignup}
            sx={{ mt: 3, mb: 5, bgcolor: "black", color: "white" }}
            startIcon={<GoogleIcon />}
          >
            Sign Up with Google
          </Button>
          <TextField
            style={{ color: "#000000" }}
            variant="outlined"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoFocus
          />
          <TextField
            variant="outlined"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
          />
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Signup
          </Button>

          <Box sx={{ mt: 2 }} textAlign="center">
            <Typography variant="body2" component="p">
              Already have an account? <Link to="/login">Login</Link>
            </Typography>
          </Box>
          <br></br>
          <Box display="flex" justifyContent="center" mb={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              style={{ color: "#000", borderColor: "#000" }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
