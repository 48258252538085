import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

const AppBarComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" style={{ backgroundColor: "#000000" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose} component={Link} to="/about">
            About
          </MenuItem>
          {/* <MenuItem onClick={handleClose} component={Link} to="/blog">Blog</MenuItem> */}
          <MenuItem
            onClick={handleClose}
            component="a"
            href="https://us10.list-manage.com/contact-form?u=2d815ae9c9e9e3d7960bc020b&form_id=b2d660cf4194b7bdaa17a11c5cc3c278"
          >
            Contact
          </MenuItem>
          {/* <MenuItem onClick={handleClose} component={Link} to="/merch">Merch</MenuItem> */}
          <MenuItem
            onClick={handleClose}
            component="a"
            href="https://www.youtube.com/@NextGenMediaAI/videos"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/">
            Home
          </MenuItem>
        </Menu>
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
          <Typography variant="h6" component="div">
            NextGen AI
          </Typography>
          <Avatar
            alt="NextGen AI"
            src="https://ik.imagekit.io/mbhxghyf1m2/111_0FYYazRU6.png?updatedAt=1689291241099"
            sx={{ width: 36, height: 36, marginLeft: 2 }}
          />
        </Box>
        <Button
          color="inherit"
          component={Link}
          to="/signup"
          sx={{ fontSize: "1.1rem", px: 1.2, py: 2 }}
        >
          Signup
        </Button>
        <Button
          color="inherit"
          component={Link}
          to="/login"
          sx={{ fontSize: "1.1rem", px: 0, py: 0 }}
        >
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
