import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { TextField, Button, Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { Link } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";

const imageUrl =
  "https://ik.imagekit.io/mbhxghyf1m2/NextGen__1__w99-frOok.png?updatedAt=1689382919154";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#000000",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Login failed:", error);
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      console.log("Initiating Google Login"); // Log for debugging

      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      console.log("User signed in with Google"); // Log for debugging

      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Google Login failed:", error);

      // Log additional error information:
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
          <img
            src={imageUrl}
            alt="NextGen"
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{
            mt: 1,
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={handleGoogleLogin}
            sx={{ mt: 3, mb: 2, bgcolor: "black", color: "white" }} // Adjust margin as per your design
            startIcon={<GoogleIcon />}
          >
            Login with Google
          </Button>
          <TextField
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
          />
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Login
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 2, borderColor: "black" }} // Add borderColor here
            onClick={handleBack}
          >
            Back
          </Button>
          <Box sx={{ mt: 2 }} textAlign="center">
            <Typography variant="body2" component="p">
              Don't have an account? <Link to="/signup">Signup</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
