import React, { useState } from "react";
import { Button, Typography, Container } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { subscribeToMailchimp } from "./mailchimp_signup";
import { trackSegmentEvent } from "./segmentAnalytics";

const db = getFirestore();

const EmailSignup2 = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleGoogleSignup = async () => {
    trackSegmentEvent("Top Hero Google Signup Attempted");

    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      console.log("Initiating Google Signup");

      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      console.log("User signed in with Google");

      trackSegmentEvent("User Successfully signed in with Hero Google", {
        email: user.email,
        name: user.displayName,
      });

      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email,
        firstName: user.displayName,
        lastName: "",
        total_video_seconds: 0,
      });

      try {
        await subscribeToMailchimp(user.email, user.displayName, "");
        console.log("User successfully subscribed to Mailchimp");
      } catch (error) {
        console.error("Failed to subscribe user to Mailchimp:", error);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Hero Google Signup failed:", error);

      // Log additional error information:
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);

      // Track failed signup
      trackSegmentEvent("User Failed to Signup with Hero Top Google", {
        error_code: error.code,
        error_message: error.message,
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoogleSignup}
        // startIcon={<GoogleIcon />}
        sx={{
          fontSize: "1.2em",
          padding: "10px 20px",
          width: "50%",
          transition: "0.3s",
          "&:hover": {
            fontSize: "1.4em",
            padding: "12px 24px",
            width: "60%",
          },
        }}
      >
        Get Started
      </Button>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Container>
  );
};

export default EmailSignup2;
