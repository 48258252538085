import { motion } from "framer-motion"; // Import the necessary package

// SubscriptionInfoCard.js
import { Box, Card, CardContent, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";

const SubscriptionInfoCard = () => (
  <Box height="100%" display="flex" justifyContent="center" width="100%">
    {/* You would replace the Card component with the motion equivalent and specify the animations you'd like */}
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        whileHover={{
          scale: 1.05,
          boxShadow: "0px 0px 8px rgba(255, 255, 255, 0.8)",
        }}
      ></motion.div>
      <Card
        sx={{
          width: "100%", // set width to 100%
          mt: 5.5,
          bgcolor: "white",
          color: "black",
          boxShadow: "4px 4px 6px rgba(255, 255, 255, 0.4)",
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="black"
              textAlign="center"
            >
              Subscription Benefits
            </Typography>
            <Typography
              variant="body2"
              color="black"
              lineHeight="2"
              textAlign="center"
            >
              <Box
                component="ul"
                style={{
                  listStyleType: "none",
                  padding: 0,
                  paddingLeft: 0,
                  textAlign: "left",
                }}
              >
                <li>
                  <CheckCircleIcon
                    style={{ color: green[500], verticalAlign: "middle" }}
                  />{" "}
                  $0 Upfront
                </li>
                <li>
                  <CheckCircleIcon
                    style={{ color: green[500], verticalAlign: "middle" }}
                  />{" "}
                  You Only Pay For What You Use
                </li>
                <li>
                  <CheckCircleIcon
                    style={{ color: green[500], verticalAlign: "middle" }}
                  />{" "}
                  Unlimited Generations
                </li>
                <li>
                  <CheckCircleIcon
                    style={{ color: green[500], verticalAlign: "middle" }}
                  />{" "}
                  Supports the Continued Development of this App
                </li>
              </Box>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  </Box>
);

export default SubscriptionInfoCard;
