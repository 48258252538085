import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import MovieIcon from "@mui/icons-material/Movie"; // Icon for Entertainment
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"; // Icon for Business
import SchoolIcon from "@mui/icons-material/School"; // Icon for Education

const useCases = [
  {
    icon: <MovieIcon style={{ color: "white" }} />,
    title: "Entertainment",
    description:
      "Produce content for YouTube and social media, grow your channels with AI powered media!",
  },
  {
    icon: <BusinessCenterIcon style={{ color: "white" }} />,
    title: "Business",
    description:
      "Streamline your business's content production for marketing, communication, and more.",
  },
  {
    icon: <SchoolIcon style={{ color: "white" }} />,
    title: "Education",
    description:
      "Create engaging educational materials with ease, videos on any topic in seconds.",
  },
];

const UseCaseCards = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },
      justifyContent: "space-between",
      gap: "1em",
      padding: "1em",
      maxWidth: "800px",
      margin: "auto",
    }}
  >
    {useCases.map((useCase, i) => (
      <Card
        key={i}
        sx={{
          width: { xs: "100%", sm: "260px" },
          boxShadow: 3,
          textAlign: "center",
          backgroundColor: "#000",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ color: "#fff", margin: "auto", padding: "20px" }}>
          {useCase.icon}
        </Box>
        <CardContent
          sx={{ backgroundColor: "#fff", color: "#000", flexGrow: 1 }}
        >
          <Typography variant="h6" gutterBottom>
            {useCase.title}
          </Typography>
          <Typography variant="body2">{useCase.description}</Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
);

export default UseCaseCards;
