import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#000000",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
  },
});

const Cancel = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Payment Cancelled
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your payment was cancelled.
          </Typography>
          <Box sx={{ mt: 2 }} textAlign="center">
            <Typography variant="body2" component="p">
              Go back to the <Link to="/">main page</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Cancel;
