import React from 'react';
import { Typography, Button, Box, Container } from '@mui/material';
import AppBarComponent from './AppBarComponent';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';  // Import Footer
import EmailSignup from './EmailSignup';

const Blog = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}> 
      <AppBarComponent />

      <Container maxWidth="md">
        <Box sx={{ mt: 8, mb: 2, textAlign: 'left', p: 3 }}>
          <Typography variant="h2" component="h1" gutterBottom align="center">
            Press Release
          </Typography>
          <p></p>
          <Typography variant="h6" component="p" color="text.secondary" sx={{ mt: 3, mb: 6 }}>
            <b>FOR IMMEDIATE RELEASE</b><br/><br/>
            <b>NEXTGEN MEDIA UNVEILS REVOLUTIONARY CONTENT CREATION PLATFORM</b> <br/><br/>
            <b>POWERED BY GPT-4, STABLE DIFFUSION, AND AI VOICE NARRATION</b><br/><br/>
            Los Angeles, July 7 - NextGen Media, a fast-emerging tech startup, is thrilled to announce the launch of its groundbreaking content creation platform. The cutting-edge solution leverages the power of the GPT-4 stable diffusion model and advanced AI voice narration to revolutionize the way people generate content, offering an astounding tenfold increase in speed.<br/><br/>
            NextGen Media's new platform transforms the traditional content creation process, combining the sophistication of artificial intelligence with a user-friendly interface that both novice and seasoned creators can quickly adapt to. As the first content generation solution of its kind to incorporate GPT-4 stable diffusion technology, NextGen Media is poised to redefine the industry's content creation standards.<br/><br/>
            "Our innovative platform is designed to democratize content creation," says [Founder/CEO's name, title]. "By integrating AI voice narration and the GPT-4 stable diffusion model, we're not only amplifying content creation speed, but also providing unparalleled quality. Our mission is to empower creators to convey their stories more effectively and efficiently."<br/><br/>
            Beyond speed, NextGen Media's platform ensures the delivery of high-quality, contextually accurate content. It does so by leveraging the advanced language understanding capabilities of the GPT-4 model, capable of understanding complex linguistic patterns and generating human-like text. Coupled with AI-powered voice narration, the platform offers users a range of vocal tones and styles, enabling them to customize their content's delivery to their specific audience.<br/><br/>
            NextGen Media's platform signifies a significant leap forward in content creation technology. The platform's easy-to-use interface ensures that anyone can take advantage of the latest AI technologies, irrespective of their technical know-how, effectively bridging the gap between advanced technology and everyday creators.<br/><br/>
            <b>About NextGen Media</b><br/><br/>
            NextGen Media is an innovation-driven tech startup dedicated to pushing the boundaries of content creation. Focused on merging sophisticated AI technologies with user-centered design, NextGen Media aims to revolutionize the way creators generate and share their stories.<br/><br/>
            For more information, please visit www.NextGenMediaAI.com.<br/><br/>
            <b>Contact:</b><br/><br/>
            James Coholan || Founder || NextGenMediAI@gmail.com<br/>
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }} textAlign="center">
              <Typography variant="h4">Stay In Touch</Typography>
  <EmailSignup />
          <Button type="button" variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
        </Box>
      </Container>
      <Box sx={{ flexGrow: 1 }} /> {/* This is the spacer */}
      <Footer /> {/* Footer goes here */}
    </Box> 
  );
};

export default Blog;