import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";

const VideoCard = ({ desktopVideoUrl, mobileVideoUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const maxWidth = isMobile ? "100%" : 1000;

  const videoUrl = isMobile ? mobileVideoUrl : desktopVideoUrl;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "40px",
        }}
      >
        <Container maxWidth="md" sx={{ width: "100%", padding: "0px" }}>
          <Paper elevation={5} sx={{ width: "100%" }}>
            <Card
              sx={{
                maxWidth: maxWidth,
                width: "100%",
                marginBottom: "20px",
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 aspect ratio */,
              }}
            >
              <ReactPlayer
                url={videoUrl}
                controls={true}
                playing={true}
                width="100%"
                height="100%"
                style={{ position: "absolute", top: 0, left: 0 }}
                loop={true}
                muted={true}
                config={{
                  file: {
                    attributes: {
                      style: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    },
                  },
                }}
              />
            </Card>
          </Paper>
        </Container>
      </Box>
    </motion.div>
  );
};

export default VideoCard;
