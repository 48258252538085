import { Button, styled } from "@mui/material";
import { keyframes } from "@emotion/react";
import mixpanel from "./mixpanel"; // Import Mixpanel from your custom file

const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 3px #ffffff, 0 0 6px #ffffff, 0 0 9px #ffffff, 0 0 12px #ffffff;
  }
  50% {
    box-shadow: 0 0 6px #ffffff, 0 0 12px #ffffff, 0 0 18px #ffffff, 0 0 24px #ffffff;
  }
  100% {
    box-shadow: 0 0 3px #ffffff, 0 0 6px #ffffff, 0 0 9px #ffffff, 0 0 12px #ffffff;
  }
`;

const hoverGlow = keyframes`
  0% {
    box-shadow: 0 0 6px #ffffff, 0 0 12px #ffffff, 0 0 18px #ffffff, 0 0 24px #ffffff;
  }
  50% {
    box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff; 
  }
  100% {
    box-shadow: 0 0 6px #ffffff, 0 0 12px #ffffff, 0 0 18px #ffffff, 0 0 24px #ffffff;
  }
`;

const GlowButton = styled(Button)(({ theme }) => ({
  animation: `${pulseGlow} 4s infinite`,
  transition: theme.transitions.create(["color", "transform"], {
    duration: theme.transitions.duration.shortest,
  }),
  color: "black",
  backgroundColor: "#ffffff",
  "&:hover": {
    animation: `${hoverGlow} 4s infinite`,
    color: "red",
    backgroundColor: "#ffffff",
    transform: "scale(1.05)",
  },
}));

const GenerateButton = ({ handleGenerate, loading }) => {
  // Define a new handler function that wraps handleGenerate and adds tracking
  const handleGenerateWithTracking = (e) => {
    mixpanel.track("Generate Video Clicked"); // Track button click
    handleGenerate(e);
  };

  return (
    <GlowButton
      variant="contained"
      onClick={handleGenerateWithTracking}
      disabled={loading}
    >
      Generate Video
    </GlowButton>
  );
};

export default GenerateButton;
