// mailchimp_signup.js
import axios from "axios";

export const subscribeToMailchimp = async (email) => {
  const serverEndpoint = `${process.env.REACT_APP_API_URL}/subscribeToMailchimp`;

  try {
    const response = await axios.post(serverEndpoint, { email });
    if (response.status !== 200) {
      throw new Error(`Server returned status code ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error("Server request failed:", error);
    throw error;
  }
};
