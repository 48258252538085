import React, { useState, useRef } from "react"; // add useRef import
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VideoGenerator from "./VideoGenerator";
import RotatingPlaceholderInput from "./RotatingPlaceholderInput";

const AppBody = ({
  topic,
  setTopic,
  setLoading,
  setDownloadLink,
  loading,
  downloadLink,
  handleFileChange,
  topics,
  setTopics,
}) => {
  const theme = useTheme();
  const [inputMode, setInputMode] = useState("text"); // initial mode is "text"
  const fileInputRef = useRef(); // define the file input ref

  return (
    <Box
      sx={{
        height: "22vh",
        maxWidth: "600px",
        margin: {
          xs: "25px auto", // reduced top margin for xs and up
          sm: "50px auto", // original top margin for sm and up
        },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "1rem",
        backgroundColor: "transparent", // set background color to transparent
        color: theme.palette.text.primary,
        padding: {
          xs: "2rem", // padding for xs and up
          sm: "1rem", // padding for sm and up
          md: "0rem", // padding for md and up
        },
      }}
    >
      <img
        src="https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/NextGen__1__w99-frOok.png?updatedAt=1695206379533"
        alt="NextGen AI"
        style={{ maxWidth: "100%", marginBottom: "20px" }}
      />

      <ToggleButtonGroup
        value={inputMode}
        exclusive
        onChange={(event, newValue) => {
          setInputMode(newValue);
          console.log(inputMode);
        }}
      >
        <ToggleButton value="text">Text</ToggleButton>
        <ToggleButton value="csv">CSV</ToggleButton>
      </ToggleButtonGroup>

      {inputMode === "text" ? (
        <RotatingPlaceholderInput
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          style={{ marginBottom: "35px" }}
        />
      ) : (
        <>
          <input
            type="file"
            id="csvFile"
            name="csvFile"
            accept=".csv"
            ref={fileInputRef}
            style={{ display: "none" }} // hide the input
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
          >
            Upload CSV
          </Button>
        </>
      )}

      <VideoGenerator
        topic={topic}
        setTopic={setTopic}
        setLoading={setLoading}
        setDownloadLink={setDownloadLink}
        loading={loading}
        downloadLink={downloadLink}
        topics={topics}
        setTopics={setTopics}
        inputMode={inputMode} // pass inputMode as prop
        setInputMode={setInputMode} // pass setInputMode as prop
      />
    </Box>
  );
};

export default AppBody;
