import React, { useState, useEffect } from "react";

const LoadingOverlay = () => {
  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTextVisible(false);
    }, 20000); // 20 seconds

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loaderContainer fadeIn">
      <div className="loadingOverlay">
        <img
          src="https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/111_0FYYazRU6.png?updatedAt=1695206280107"
          alt="Some description"
          className="logoImage fadeIn"
        />
        <div className="spinner"></div>
      </div>
      {isTextVisible && (
        <>
          <p className="loadingMessage fadeIn">
            <span className="typewriterEffect1">
              Beep boop... this may take a while 🤖
            </span>
          </p>
          <p className="loadingMessage fadeIn">
            <span className="typewriterEffect2">It's safe to click away</span>
          </p>
          <p className="loadingMessage fadeIn">
            <span className="typewriterEffect3">
              The final video will be emailed to you!
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default LoadingOverlay;
