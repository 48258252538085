import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51NrgLCDfZbpPEenLpFOAZ5fz76lMnhDNfxGs3D92ndXfZOkvthzZuLPS2WohkqOhPXqOYPAxHAazooCau3h24XHs00yRiwv7P5");
const apiUrl = process.env.REACT_APP_API_URL;

export const createStripeCheckoutSession = async (firebase_uid) => {
  try {
    // Create a checkout session
    const session = await axios.post(`${apiUrl}/create-checkout-session`, {
      firebase_uid: firebase_uid,
    });

    // Fetch the Stripe.js instance
    const stripe = await stripePromise;

    // Redirect to Stripe checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.data.id,
    });

    if (result.error) {
      console.error("Stripe checkout failed:", result.error);
      return { status: "error", message: result.error.message };
    }

    return { status: "success" };
  } catch (error) {
    console.error("Failed to create Stripe checkout session:", error);
    return { status: "error", message: error.message };
  }
};
