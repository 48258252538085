import React from 'react';
import { Typography, Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubscriptionCard = ({ title, description, price, isCurrent,cancel, link }) => {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        padding: '1rem',
        textAlign: 'center',
        border: isCurrent ? '2px solid #000000' : '2px solid transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 2, color: '#000000' }}>
          {title}
        </Typography>
        <Typography variant="h7" component="div" sx={{ mb: 4, color: '#000000' }}>
          {description}
        </Typography>
        <Typography variant="h7" component="div" sx={{ mb: 4, color: '#000000' }}>
          ${price}
        </Typography>
      </Box>
      <Box>
        {isCurrent ? (
          <Typography variant="body2" color="primary.main" sx={{ fontWeight: 'bold' }}>
            Current Plan
          </Typography>
        ) : (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary" fullWidth>
              Select Plan
            </Button>
          </a>
        )}
      </Box>
    </Box>
  );
};

const BillingPage = () => {
  const currentTier = 2; // Assuming the current user is on Tier 2
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          Subscription Plans
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: '1rem',
          marginTop: '2rem',
        }}
      >
        <SubscriptionCard
          title="No Upfront Cost"
          description="Only Pay For What You Use"
          price=".02 Cents Per Generated Second"
          cancel="Please Email NextgenMediaAI@gmail.com For Cancellations"
          isCurrent={currentTier === 2}
          // link="https://billing.stripe.com/p/login/8wMeVG9wt6is7x6000"
        />
      </Box>
      <Box sx={{ mt: 2 }} textAlign="center">
        <Button type="button" variant="outlined" color="secondary" onClick={handleBack}>
          Back
        </Button>
      </Box>
    </Container>
  );
};

export default BillingPage;
