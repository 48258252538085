import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getAuth, signOut } from "firebase/auth";
import ReactGA from "react-ga";
import AppHeader from "./AppHeader";
import AppBody from "./AppBody";
import VantaEffect from "./VantaEffect";
import mixpanel from "./mixpanel";
import Papa from "papaparse";

const getTheme = () => {
  return createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#FFFFFF",
      },
      background: {
        default: "#000000",
      },
    },
  });
};

const App = () => {
  const [topic, setTopic] = useState("");
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track("App Loaded");
    ReactGA.initialize("G-CRBE90S"); // Replace with your Google Analytics ID
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.body.style.backgroundColor = "#000000";
  }, []);

  const handleFileChange = (event) => {
    Papa.parse(event.target.files[0], {
      complete: function (results) {
        console.log("Finished:", results.data);
        setTopics(results.data);
      },
    });
  };

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      document.body.style.backgroundColor = "#F5F5F5";
      document.body.style.color = "#212121";
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <VantaEffect />
      <div style={{ position: "relative", zIndex: 1 }}>
        <ThemeProvider theme={getTheme()}>
          <AppHeader
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleLogout={handleLogout}
            navigate={navigate}
          />
          <AppBody
            topic={topic}
            setTopic={setTopic}
            setLoading={setLoading}
            setDownloadLink={setDownloadLink}
            loading={loading}
            downloadLink={downloadLink}
            handleFileChange={handleFileChange}
            topics={topics}
            setTopics={setTopics}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default App;
