import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Container, Button } from '@mui/material';
import AppBarComponent from './AppBarComponent';
import Footer from './Footer';

const MerchPage = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios({
        method: 'GET',
        url: 'https://api.printful.com/produts',
        headers: {
          'Authorization': 'Basic ' + btoa('qTlMpktAZyRkwdHQHc:'),  // replace 'YOUR_API_KEY:' with your actual key
        },
      });

      setProducts(result.data.result);
    };

    fetchData();
  }, []);

  const addToCart = (product) => {
    setCart((oldCart) => [...oldCart, product]);
    console.log(`Product ${product.id} added to cart`);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}> 
      <AppBarComponent />
      <Container maxWidth="md">
        <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
          <Typography variant="h2" component="h1" gutterBottom>
            Merchandise
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {products.map((product) => (
            <Box key={product.id} sx={{ margin: 3, textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom>
                {product.name}
              </Typography>
              <img src={product.thumbnail_url} alt={product.name} style={{ width: '100%', maxWidth: '200px' }} />
              <Typography variant="body1" component="p" color="text.secondary" sx={{ mt: 2 }}>
                {product.description}
              </Typography>
              <Button onClick={() => addToCart(product)} variant="contained" color="primary">
                Add to Cart
              </Button>
              {/* Add more product details and styles here */}
            </Box>
          ))}
        </Box>
      </Container>
      <Box sx={{ flexGrow: 1 }} /> {/* This is the spacer */}
      <Footer />
    </Box> 
  );
};

export default MerchPage;
