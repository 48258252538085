import { Button, styled } from "@mui/material";
import { keyframes } from "@emotion/react";

const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 3px #ff0000, 0 0 6px #ff0000, 0 0 9px #ff0000, 0 0 12px #ff0000;
  }
  50% {
    box-shadow: 0 0 6px #ff0000, 0 0 12px #ff0000, 0 0 18px #ff0000, 0 0 24px #ff0000;
  }
  100% {
    box-shadow: 0 0 3px #ff0000, 0 0 6px #ff0000, 0 0 9px #ff0000, 0 0 12px #ff0000;
  }
`;

const hoverGlow = keyframes`
  0% {
    box-shadow: 0 0 6px #ff0000, 0 0 12px #ff0000, 0 0 18px #ff0000, 0 0 24px #ff0000;
  }
  50% {
    box-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000; 
  }
  100% {
    box-shadow: 0 0 6px #ff0000, 0 0 12px #ff0000, 0 0 18px #ff0000, 0 0 24px #ff0000;
  }
`;

const GlowButton = styled(Button)(({ theme }) => ({
  animation: `${pulseGlow} 4s infinite`,
  transition: theme.transitions.create(
    ["color", "transform", "backgroundColor"],
    {
      duration: theme.transitions.duration.shortest,
    }
  ),
  color: "#ffffff", // Changed to white
  backgroundColor: "#ff0000",
  "&:hover": {
    animation: `${hoverGlow} 4s infinite`,
    backgroundColor: "red",
    transform: "scale(1.05)",
  },
}));

const SubscriptionButton = ({ handleSubscribe, loading }) => (
  <GlowButton
    variant="contained"
    onClick={handleSubscribe}
    disabled={loading}
    fullWidth
  >
    Please Subscribe To Continue
  </GlowButton>
);

export default SubscriptionButton;
