import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageCarousel = ({images}) => {
    return (
        <Carousel autoPlay infiniteLoop useKeyboardArrows dynamicHeight emulateTouch>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt="Carousel" style={{ maxWidth: 800, width: '100%', marginBottom: '20px' }} />
                </div>
            ))}
        </Carousel>
    );
}

export default ImageCarousel;
