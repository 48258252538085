// segmentAnalytics.js
import { Analytics } from "analytics";
import segmentPlugin from "@analytics/segment";

// Create an analytics instance
const analytics = Analytics({
  app: "NextGen Media",
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

// A function to track events
export const trackSegmentEvent = (eventName) => {
  analytics.track(eventName);
};

export default analytics;
