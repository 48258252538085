import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
                marginTop: '40px',
                backgroundColor: '#000000', // Set the background color to black
                color: 'white',
                paddingBottom: '20px',
                gap: '1rem', // Add gap for more separation
            }}
        >
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '35px', // Increase gap
                    padding: '20px', // Add padding
                    marginTop: '30px',
                }}
            >
                <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>About</Link>
                {/* <Link to="/blog" style={{ color: 'white', textDecoration: 'none' }}>Blog</Link> */}
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px', // Increase gap
                    padding: '10px', // Add padding
                }}
            >
                <IconButton color="default">
    <a href="https://www.youtube.com/@NextGenMediaAI/videos" target="_blank" rel="noopener noreferrer">
        <FaYoutube style={{ color: 'white' }} />
    </a>
</IconButton>
<IconButton color="default">
    <a href="https://twitter.com/NextGenMediaAI" target="_blank" rel="noopener noreferrer">
        <TwitterIcon style={{ color: 'white' }} />
    </a>
</IconButton>
            </Box>
            <Typography variant="body1" sx={{ padding: '25px' }}>  {/* Add padding here */}
                © {new Date().getFullYear()} NextGen AI
            </Typography>
        </Box>
    );
}

export default Footer;
