/* global dataLayer */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";
import { motion } from "framer-motion";
import Footer from "./Footer";
import ImageCarousel from "./ImageCarousel";
import VideoCard from "./VideoCard";
import AppBarComponent from "./AppBarComponent";
import ReactGA from "react-ga";
import EmailSignup from "./EmailSignup";
import EmailSignup2 from "./EmailSignup2";
import ScheduleCallButton from "./ScheduleCallButton";
import UseCaseCards from "./UseCaseCards";
import mixpanel from "../mixpanel";
import { loadSegmentScript } from "./segmentScript";
import { trackSegmentEvent } from "./segmentAnalytics";
import {
  trackButtonClick,
  trackScrollDepth,
  trackTimeOnPage,
} from "./analytics";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

const Section = ({ imageUrl, children, marginTop }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "20px",
          marginBottom: "40px",
          marginTop: marginTop || "0px",
        }}
      >
        {imageUrl ? (
          <Card sx={{ maxWidth: 800, width: "100%", marginBottom: "20px" }}>
            <CardMedia
              component="img"
              sx={{
                maxWidth: "100%",
                height: "auto",
              }}
              image={imageUrl}
              alt="Section Image"
            />
          </Card>
        ) : null}
        {children}
      </Box>
    </motion.div>
  );
};

const CarouselCard = ({ images }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const maxWidth = matches ? "100%" : 2000;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "40px",
        }}
      >
        <Container maxWidth="md">
          <Paper elevation={5}>
            <Card
              sx={{ maxWidth: maxWidth, width: "100%", marginBottom: "20px" }}
            >
              <ImageCarousel images={images} />
            </Card>
          </Paper>
        </Container>
      </Box>
    </motion.div>
  );
};

const Landing = () => {
  useEffect(() => {
    ReactGA.initialize("G-CRBZ56E90S");
    trackSegmentEvent("Landing Page Loaded");

    // Existing Google Analytics script
    const scriptGA = document.createElement("script");
    scriptGA.src = "https://www.googletagmanager.com/gtag/js?id=G-56E90S";
    scriptGA.async = true;
    document.head.appendChild(scriptGA);

    // Google Tag
    const scriptGTag = document.createElement("script");
    scriptGTag.async = true;
    scriptGTag.src = "https://www.googletagmanager.com/gtag/js?id=AW-1515175";
    document.head.appendChild(scriptGTag);

    // Initialization
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      dataLayer.push(arguments);
    };
    window.gtag("js", new Date());

    // Configuring both Google Analytics and Google Tag
    window.gtag("config", "G-CRB6E90S");
    window.gtag("config", "AW-112554175");

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleButtonClick = () => {
    trackSegmentEvent("Button Clicked");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: "100vh",
          width: "100%",
        }}
      >
        <AppBarComponent />
        <Section marginTop="40px">
          <img
            src="https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/NextGen__1__w99-frOok.png?updatedAt=1695206379533"
            alt="NextGen AI"
            style={{ maxWidth: 900, width: "100%", marginBottom: "20px" }}
          />
          <Typography variant="h3">One Click AI Content Production</Typography>
          <br></br>
          <EmailSignup2 />
        </Section>
        <VideoCard
          desktopVideoUrl="https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/NextGen_R4FPi-VsU.mp4?updatedAt=1695206298002"
          mobileVideoUrl="https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/Verrrt_TLkZRDsGA.mp4?updatedAt=1695206299720"
        />
        <Section>
          <Section>
            <Typography variant="h3">Create For Your Use Case</Typography>
            <UseCaseCards />
            <Section></Section>
            <EmailSignup2 />
          </Section>
        </Section>
        <CarouselCard
          images={[
            process.env.REACT_APP_IMAGE_1,
            process.env.REACT_APP_IMAGE_2,
            process.env.REACT_APP_IMAGE_3,
            process.env.REACT_APP_IMAGE_4,
            process.env.REACT_APP_IMAGE_5,
            process.env.REACT_APP_IMAGE_6,
            process.env.REACT_APP_IMAGE_7,
            process.env.REACT_APP_IMAGE_8,
          ]}
        />
        <Section>
          <Typography variant="h3">Production Superpowers</Typography>
          <Box style={{ maxWidth: "80%", margin: "0 auto" }}>
            <Typography variant="body1">
              No Expertise Required. Single Click Production Using the Most
              Sophisticated AI Tools Available.
            </Typography>
          </Box>
          <br></br>
          <Typography variant="h4">Start Generating</Typography>
          <EmailSignup />
        </Section>
        <br></br>
        <Section imageUrl={"https://ik.imagekit.io/wnd6aqvsu/NextGen%20Media%20Assets/1234.png?updatedAt=1695206281399"}>
          <Typography variant="h3">Infinite AI Media Generation</Typography>
          <h3>No Upfront Cost</h3>
          <h3>Only Pay For What You Use</h3>
          <h3>Unlimited Access</h3>
          <ScheduleCallButton />
        </Section>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Landing;
