import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";

const ScheduleCallButton = () => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  // Function to handle button click
  const handleButtonClick = () => {
    setIsCalendlyOpen(!isCalendlyOpen);
  };

  // Function to add Calendly script to head
  const addCalendlyScript = () => {
    // Check if the script already exists
    if (!document.querySelector("#calendlyScript")) {
      const scriptCalendly = document.createElement("script");
      scriptCalendly.id = "calendlyScript";
      scriptCalendly.src =
        "https://assets.calendly.com/assets/external/widget.js";
      scriptCalendly.async = true;
      document.head.appendChild(scriptCalendly);
    }
  };

  useEffect(() => {
    addCalendlyScript();

    // Cleanup function
    return () => {
      if (window.Calendly) {
        window.Calendly.closePopupWidget();
        const scriptCalendly = document.querySelector("#calendlyScript");
        scriptCalendly && scriptCalendly.remove();
      }
    };
  }, [isCalendlyOpen]);

  useEffect(() => {
    if (window.Calendly && isCalendlyOpen) {
      window.Calendly.initInlineWidget({
        url: "https://calendly.com/yourcalednly",
        parentElement: document.querySelector(".calendly-inline-widget"),
        prefill: {},
      });
    }
  }, [isCalendlyOpen]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        style={{ fontSize: "1.2em", padding: "10px 20px" }}
      >
        Schedule a Call
      </Button>

      {isCalendlyOpen && (
        <div
          className="calendly-inline-widget"
          style={{ minWidth: "320px", height: "700px" }}
        ></div>
      )}
    </div>
  );
};

export default ScheduleCallButton;
