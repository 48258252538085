import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material";
import { keyframes } from "@emotion/react";
import mixpanel from "./mixpanel"; // Import Mixpanel from your custom file

const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 3px #FF0000, 0 0 6px #FF0000, 0 0 9px #FF0000, 0 0 12px #FF0000;
  }
  50% {
    box-shadow: 0 0 6px #FF0000, 0 0 12px #FF0000, 0 0 18px #FF0000, 0 0 24px #FF0000;
  }
  100% {
    box-shadow: 0 0 3px #FF0000, 0 0 6px #FF0000, 0 0 9px #FF0000, 0 0 12px #FF0000;
  }
`;

const hoverGlow = keyframes`
  0% {
    box-shadow: 0 0 6px #FF0000, 0 0 12px #FF0000, 0 0 18px #FF0000, 0 0 24px #FF0000;
  }
  50% {
    box-shadow: 0 0 10px #FF0000, 0 0 20px #FF0000, 0 0 30px #FF0000, 0 0 40px #FF0000; 
  }
  100% {
    box-shadow: 0 0 6px #FF0000, 0 0 12px #FF0000, 0 0 18px #FF0000, 0 0 24px #FF0000;
  }
`;

const GlowButton = styled(Button)(({ theme }) => ({
  animation: `${pulseGlow} 4s infinite`,
  transition: theme.transitions.create(["color", "transform"], {
    duration: theme.transitions.duration.shortest,
  }),
  color: "white",
  backgroundColor: "red",
  "&:hover": {
    animation: `${hoverGlow} 4s infinite`,
    color: "white",
    backgroundColor: "red",
    transform: "scale(1.05)",
  },
  marginTop: "0px",
  width: "40%",
}));

const DownloadButton = ({ downloadLink }) => {
  // Define the new click handler
  const handleClick = (e) => {
    mixpanel.track("Download Video Clicked"); // Track download button click
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <GlowButton variant="contained" onClick={handleClick}>
        <a
          href={downloadLink}
          style={{
            color: "white",
            textDecoration: "none",
          }}
          download
        >
          Download Video Here
        </a>
      </GlowButton>
    </div>
  );
};

export default DownloadButton;
