import React, { useEffect, useState, useRef } from "react";
import HALO from "vanta/dist/vanta.halo.min";
import * as THREE from "three";

const VantaEffect = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        HALO({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x282828,
          baseColor: 0x111111,
          size: 1.8,
          aura: 0.5,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "calc(100% + 700px)",
        top: "-700px",
        left: "0px",
        zIndex: -1,
        filter: "brightness(15%)",
      }}
    >
      <div ref={myRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default VantaEffect;
