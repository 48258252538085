import React, { useState, useEffect } from "react";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "white",
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderWidth: 2,
      boxShadow: "0 0 10px white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      boxShadow: "0 0 10px white",
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  "&.scale-up .MuiOutlinedInput-root": {
    transform: "scale(1.05)",
  },
}));

const RotatingPlaceholderInput = ({ value, onChange, style }) => {
  const placeholders = [
    "Create content illustrating the benefits of my unique business model",
    "Who would win in a fight, James Bond or Jason Bourne?",
    "Produce a piece of content about the success story of my company",
    "Generate content showcasing my top-selling products",
    "Is ChatGPT sentient",
    "Create a video about the most iconic moments in pop music that",
    "Make a video detailing the rise and influence of the Marvel Cinematic Universe",
    "Craft content showcasing the testimonials of my satisfied clients",
    "Compare the leadership styles of Steve Jobs and Elon Musk in a video",
    "Produce a video highlighting the milestones of my company's journey",
    "Generate content about the influence of 'Game of Thrones' on television",
    "Create a video about the top fashion trends of the last decade",
    "Make content about the history and impact of Anime culture worldwide from my viewpoint",
    "Generate a video showcasing my company's response to a market challenge",
    "Create content that explains the story behind my brand",
    "Explain the concept of digital transformation and its relevance today in a video",
    "Craft a piece of content detailing my unique value proposition",
    "Make a video showcasing the journey of a product from concept to market in my industry",
    "Create a video discussing the rise of eSports and its impact on traditional sports",
    "Generate a piece of content about the cultural impact of the 'Harry Potter' series",
    "Produce a video highlighting my company's corporate culture",
    "Who would win in a cook-off, Gordon Ramsay or Jamie Oliver?",
    "Discuss the evolution of memes and their cultural significance",
    "Create a video that talks about the impact of 'The Simpsons' on animation and television",
    "Compare the music styles of The Beatles and The Rolling Stones",
    "How has 'Star Wars' influenced modern science fiction?",
    "Which superhero power would you choose, flight or invisibility, and why?",
    "Imagine if the world was like a video game, what features would you want?",
    "Discuss the 'Friends' phenomenon and its impact on sitcoms",
    "How has TikTok changed the social media landscape?",
    "Create a video about how reality TV has shaped popular culture",
    "Discuss the 'Breaking Bad' phenomenon and its influence on modern television",
    "Generate content that explores the rise of K-Pop globally",
    "Create a video comparing the movie adaptations of 'Lord of the Rings' and 'Harry Potter'",
    "Who would win in a space race, Jeff Bezos or Richard Branson?",
  ];

  const [placeholder, setPlaceholder] = useState(placeholders[0]);
  const [isFading, setIsFading] = useState(false);
  const [isScaled, setIsScaled] = useState(false);

  useEffect(() => {
    const getRandomPlaceholder = () => {
      const randomIndex = Math.floor(Math.random() * placeholders.length);
      return placeholders[randomIndex];
    };

    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setPlaceholder(getRandomPlaceholder());
        setIsFading(false);
      }, 1000);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledTextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
      className={`${isFading ? "fading" : ""} ${isScaled ? "scale-up" : ""}`}
      variant="outlined"
      onMouseEnter={() => setIsScaled(true)}
      onMouseLeave={() => setIsScaled(false)}
    />
  );
};

export default RotatingPlaceholderInput;
