import React, { useState } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { subscribeToMailchimp } from "./mailchimp_signup";

const db = getFirestore();

const EmailSignup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignup = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        total_video_seconds: 0,
      });

      try {
        await subscribeToMailchimp(email, firstName, lastName);
        console.log("User successfully subscribed to Mailchimp");
      } catch (error) {
        console.error("Failed to subscribe user to Mailchimp:", error);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Signup failed:", error);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email,
        firstName: user.displayName,
        lastName: "",
        total_video_seconds: 0,
      });

      try {
        await subscribeToMailchimp(user.email, user.displayName, "");
        console.log("User successfully subscribed to Mailchimp");
      } catch (error) {
        console.error("Failed to subscribe user to Mailchimp:", error);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Google Signup failed:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSignup}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          onClick={handleGoogleSignup}
          startIcon={<GoogleIcon />}
        >
          Sign Up with Google
        </Button>

        <TextField
          variant="outlined"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
        />

        <TextField
          variant="outlined"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
        />

        <TextField
          variant="outlined"
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
        />

        <TextField
          variant="outlined"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
        />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}

        <Button type="submit" fullWidth variant="contained" color="primary">
          Signup
        </Button>
      </form>
    </Container>
  );
};

export default EmailSignup;
