import React, { useEffect } from "react";
import { Button, Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import mixpanel from "../mixpanel"; // Import mixpanel from mixpanel.js

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // This will track an event named 'Payment Successful' whenever this component is rendered
    mixpanel.track("Payment Successful");
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const goToLogin = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          Payment Successful
        </Typography>
        <Typography variant="body2" component="p">
          Your payment was processed successfully.
        </Typography>
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, backgroundColor: "#000000", color: "#ffffff" }}
          onClick={goToLogin}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
