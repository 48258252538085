import React from "react";
import { Menu, MenuItem } from "@mui/material";

const AppMenu = ({ anchorEl, handleClose, handleLogout, navigate }) => (
  <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={handleLogout}>Logout</MenuItem>
    <MenuItem
      onClick={handleClose}
      component="a"
      href="https://us10.list-manage.com/contact-form?u=2d815ae9c9e9e3d0b&form_id=aa17a11cc3c278"
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact
    </MenuItem>
    {/* <MenuItem onClick={() => navigate('/billing')}>Billing</MenuItem> */}
  </Menu>
);

export default AppMenu;
