import React, { useState, useEffect } from "react";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import { auth } from "./firebase";
import Login from "./components/Login";
import Signup from "./components/Signup";
import App from "./App";
import Landing from "./components/Landing";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import BillingPage from "./components/BillingPage";
import About from "./components/About";
import Blog from "./components/Blog";
import MerchPage from "./components/Merch";

const AppRouter = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, []);

  const isExtension = window.location.protocol === "chrome-extension:";
  const Router = isExtension ? HashRouter : BrowserRouter;

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/billing" element={<BillingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/merch" element={<MerchPage />} />
        <Route path="/" element={authenticated ? <App /> : <Landing />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
