import React, { useState } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import GenerateButton from "./GenerateButton";
import SubscriptionButton from "./SubscriptionButton"; // Make sure the path is correct
import LoadingOverlay from "./LoadingOverlay";
import DownloadButton from "./DownloadButton";
import "./VideoGenerator.css";
import { createStripeCheckoutSession } from "./stripeHelpers";
import SubscriptionInfoCard from "./SubscriptionInfoCard";
import { Box } from "@mui/material";
import ErrorMessage from "./ErrorMessage";
// import UploadToggle from "./UploadToggle";

const apiUrl = process.env.REACT_APP_API_URL;

const VideoGenerator = ({
  topic,
  setTopic,
  setLoading,
  setDownloadLink,
  loading,
  downloadLink,
  topics,
  setTopics,
  inputMode, // get inputMode from props directly
}) => {
  const [showSubscribeButton, setShowSubscribeButton] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadToYoutube, setUploadToYoutube] = useState(false);

  const generateVideo = async () => {
    try {
      if (
        (inputMode === "text" && !topic) ||
        (inputMode === "csv" && topics.length === 0)
      ) {
        setErrorMsg("Please provide a topic or upload a CSV file");
        return;
      }
      setLoading(true);
      setDownloadLink("");
      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken(true); // Force-refresh the ID token

      if (inputMode === "csv") {
        for (const topicRow of topics) {
          const topic = topicRow[0];
          let imagePrompts = topicRow[1]; // get image prompts from CSV
          imagePrompts = imagePrompts.split(","); // split the image prompts into an array
          const payload = { topic, prompts: imagePrompts }; // include image prompts in payload
          const response = await generateVideoForTopic(
            payload,
            idToken,
            uploadToYoutube
          );
          if (response.status === "success") {
            const videoUrl = response.video_url;
            setDownloadLink(videoUrl);
            setShowSubscribeButton(false);
            setErrorMsg("");
          }
        }
      } else {
        const payload = { topic };
        const response = await generateVideoForTopic(
          payload,
          idToken,
          uploadToYoutube
        );
        if (response.status === "success") {
          const videoUrl = response.video_url;
          setDownloadLink(videoUrl);
          setShowSubscribeButton(false);
          setErrorMsg("");
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const generateVideoForTopic = async (payload, idToken, uploadToYoutube) => {
    try {
      const response = await axios.post(
        //main request

        `${apiUrl}/generate`,
        { ...payload, uploadToYoutube },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail === "Token expired"
      ) {
        // If the error is due to token expiration, refresh the token and retry the request
        const auth = getAuth();
        idToken = await auth.currentUser.getIdToken(true);
        return generateVideoForTopic(payload, idToken);
      } else {
        console.error(error);
        // handle specific error cases here
        if (
          error.response &&
          error.response.data.detail &&
          error.response.data.detail.error === "Video limit reached"
        ) {
          setShowSubscribeButton(true);
          setErrorMsg(
            "You've reached the video limit, please subscribe to continue using the service."
          );
        } else if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
          setErrorMsg(
            `Server responded with error: ${JSON.stringify(
              error.response.data
            )}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
          setErrorMsg("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          setErrorMsg(`Request setup error: ${error.message}`);
        }
        throw error; // rethrow the error if it should be handled upstream
      }
    }
  };

  const handleSubscribe = () => {
    const auth = getAuth();
    if (auth.currentUser) {
      createStripeCheckoutSession(auth.currentUser.uid);
    } else {
      console.error("User not authenticated");
    }
  };

  return (
    <>
      {!showSubscribeButton && (
        <>
          <GenerateButton handleGenerate={generateVideo} loading={loading} />
          {/* <UploadToggle
            uploadToYoutube={uploadToYoutube}
            setUploadToYoutube={setUploadToYoutube}
          /> */}
        </>
      )}
      <ErrorMessage message={errorMsg} />
      {showSubscribeButton && (
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <SubscriptionButton
            handleSubscribe={handleSubscribe}
            loading={loading}
          />
          <SubscriptionInfoCard />
        </Box>
      )}
      {loading && <LoadingOverlay />}
      {downloadLink && !loading && (
        <DownloadButton downloadLink={downloadLink} />
      )}
    </>
  );
};

export default VideoGenerator;
