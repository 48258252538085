import React from "react";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AppMenu from "./AppMenu";

const AppHeader = ({
  handleClick,
  anchorEl,
  handleClose,
  handleLogout,
  navigate,
}) => (
  <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000" }}>
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <AppMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleLogout={handleLogout}
        navigate={navigate}
      />
    </Toolbar>
  </AppBar>
);

export default AppHeader;
