import React, { useEffect, useState } from "react";
import "./ErrorMessage.css";

const ErrorMessage = ({ message }) => {
  const [displayMessage, setDisplayMessage] = useState("");
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (message) {
      setKey(Math.random());
      setDisplayMessage(message);
      const timer = setTimeout(() => {
        setDisplayMessage("");
      }, 4000); // delay before message disappears matches animation duration
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div key={key} className="error-message">
      {displayMessage}
    </div>
  );
};

export default ErrorMessage;
