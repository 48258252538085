import React from "react";
import { Typography, Button, Box, Container } from "@mui/material";
import { Link } from "react-router-dom";
import AppBarComponent from "./AppBarComponent";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import EmailSignup from "./EmailSignup";

const About = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBarComponent />
      <Container maxWidth="md">
        <Box sx={{ mt: 8, mb: 2 }} textAlign="center">
          <Typography variant="h2" component="h1" gutterBottom>
            About
          </Typography>
          <Typography variant="h4" component="h1" gutterBottom>
            NextGen Media Creation Software
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ mt: 3, mb: 6 }}
          >
            NextGen is revolutionizing the media creation industry by
            introducing powerful, intuitive, and accessible tools for all
            creators. We aim to provide a platform that inspires creativity,
            fosters innovation, and pushes the boundaries of what's possible.
            Our suite of tools is designed to cater to both beginners and
            professionals, ensuring that anyone can bring their vision to life.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }} textAlign="center">
          <Typography variant="h4">Get Started</Typography>
          <br></br>
          <br></br>
          <EmailSignup />
          <br></br>
          <br></br>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleBack}
          >
            Back
          </Button>
        </Box>
      </Container>
      <Box sx={{ flexGrow: 1 }} />
      <Footer />
    </Box>
  );
};

export default About;
