// Firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAD0XwD2F_-iKEPJMYHBPr6g_RYuPgCf38",
  authDomain: "webaffinity-gpt.firebaseapp.com",
  projectId: "webaffinity-gpt",
  storageBucket: "webaffinity-gpt.appspot.com",
  messagingSenderId: "1046219907605",
  appId: "1:1046219907605:web:8fa7eac77ecf7e3872e41c",
  measurementId: "G-PB26X96H9Q",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
